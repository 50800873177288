import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styled from '@emotion/styled';

const faqs = [
    {
        question: "Can this system be developed using Microsoft tools, such as OneDrive and JIRA Work Management?",
        answer: "Absolutely. Here's an example setup: JIRA Work Management for task management, OneDrive for secure document storage, SharePoint as the main dashboard, DocuSign for document signing, and Power Automate for notifications.",
    },
    {
        question: "Can you provide a session on how this system is organized?",
        answer: "Yes, we offer guided sessions to show you how the system is structured, including best practices for workflow, task management, and document storage.",
    },
    {
        question: "What benefits can we expect?",
        answer: "Efficiency, collaboration, compliance, and customization to your specific requirements.",
    },
    {
        question: "How does onboarding work?",
        answer: "We work closely with you to understand your workflow, recommend the right setup, and tailor the system to fit your needs.",
    },
    {
        question: "How do you collect client data?",
        answer: "Our system categorizes client data into General Data (without documents) and Document-Verified Data. Associates collect key information and verify document accuracy.",
    },
    // {
    //     question: "What is the pricing structure?",
    //     answer: "Standard Plan: AUD 250/month for up to 10 users. Additional setup fees apply based on workflow requirements.",
    // },
    {
        question: "Can you assist with website redevelopment or additional IT services?",
        answer: "Yes, we offer IT services to enhance digital operations and ensure a cohesive experience.",
    },
    {
        question: "Where are you located?",
        answer: "Our office is at 8 Clunies Court, Brisbane Technology Park, Brisbane, Queensland.",
    },
    {
        question: "Why did you start solving migration agents’ problems?",
        answer: "Observing outdated applications and a need for process-driven collaboration inspired us to create this solution.",
    },
    {
        question: "I need a feature that’s missing. Can you add it?",
        answer: "Absolutely! Please share the feature request, and we’ll prioritize it in our development process.",
    },
    {
        question: "How does this solution work overall?",
        answer: "We can arrange a demo or refer you to our 'How It Works' section for a detailed overview.",
    },
    {
        question: "Can we integrate this workflow with JIRA Work Management?",
        answer: "Yes, we can integrate it. This typically takes six weeks and starts at AUD 3000, including training.",
    },
    {
        question: "Does this solution replace Migration Manager?",
        answer: "Our solution supports team workflows with collaboration features but does not directly replace Migration Manager.",
    },
    {
        question: "Why don’t you offer e-lodge or auto-filling for ImmiAccount?",
        answer: "We prioritize collaboration over automation based on client feedback. Automation may be added in the future.",
    },
    {
        question: "How will my clients benefit from this tool?",
        answer: "Clients receive login access to track progress, receive notifications, and upload documents securely.",
    },
];


const StyledTableContainer = styled(TableContainer)`
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const FAQTable = () => {
    return (
        <Box sx={{ my: 5, mx: 'auto', maxWidth: '1000px' }}>
            <Typography variant="h4" color="primary" align="center" gutterBottom>
                Frequently Asked Questions
            </Typography>
            <Typography>&nbsp;</Typography>
            <StyledTableContainer component={Paper}>
                <Table aria-label="faq table">
                    <TableBody>
                        {faqs.map((faq, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ width: '35%', borderBottom: 'none' }}>
                                    <Box display="flex" alignItems="center">
                                        <HelpOutlineIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="h6" component="span">
                                            {faq.question}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ width: '65%', borderBottom: 'none' }}>
                                    <Box display="flex" alignItems="center">
                                        <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
                                        <Typography variant="body1" component="span">
                                            {faq.answer}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default FAQTable;
